
.slick-dots {
    position: absolute;
    bottom: 170px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0px 10px;
    padding: 0;
    cursor: pointer;
    transition: width 0.3s ease-in-out;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 12px;
    height: 12px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    border-radius: 50%;
    outline: none;
    background: #fff;
    opacity: 0.3;
  }

  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }

  .slick-dots li button:before {
    content: none;
  }

  .slick-dots li.slick-active button {
    background: #fff;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    background: #fff;
    opacity: 1;
  }