
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Satoshi-400";
  src: url("./fonts/Satoshi-Regular.otf");
}

@font-face {
  font-family: "Satoshi-500";
  src: url("./fonts/Satoshi-Medium.otf");
}

@font-face {
  font-family: "Satoshi-700";
  src: url("./fonts/Satoshi-Bold.otf");
}

@font-face {
  font-family: "Satoshi-900";
  src: url("./fonts/Satoshi-Black.otf");
}

/* badges */
.pending {
  @apply text-[#FFA500] font-Satoshi500
}

.success {
  @apply text-[#659F37] font-Satoshi500
}

.failed {
  @apply text-[#FF0000] font-Satoshi500
}


/* progress bar */
.progress{
  background-color: #235643;
}

/* PIN styles */
.pincode-input-container .pincode-input-text{
  border-radius: 4px;
  margin-right: 8px !important;
  appearance: none;
  
}

.pincode-input-container .pincode-input-text:last-child{
  margin-right: 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
}